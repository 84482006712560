.home-main-container{
    height: auto; /* Height adjusts based on content */
    min-height: 100vh; /* Minimum height of the viewport height */
    /* background-color: #282C33; */
    background: linear-gradient(135deg, #010101 20%, #000000 50%, #000000 80%);
    box-sizing: none;
    color: #282C33;
  }

/* In your CSS */
.horizontal-line {
    margin: 0;
    background-color: #282C33;
    border: none; /* Remove default border */
    border-top: 2px solid #C778DD; /* Set line thickness and color */
  }

.details-container {
background: linear-gradient(135deg, #000000 20%, #0d1f3f 50%, #000000 80%);
border-top: 2px solid #000000; /* Set line thickness and color */
border-bottom: 2px solid #000000;
color: white;
display: flex;
flex-direction: column;
justify-content: center; /* Centers vertically */
align-items: center; /* Centers horizontally */
height: 70vh; /* Takes full height of the viewport */
text-align: center;
font-family: Chakra Petch;
margin-top: 20px;
margin-left: 20px;
margin-right: 25px;
border-radius: 20px;
}


.cursor-animation {
    position: relative;
    display: inline-block;
    padding-right: 0.2em; /* Space for the cursor */
  }
  
.cursor-animation::after {
content: '';
position: absolute;
right: 0;
bottom: 0;
width: 0.1em; /* Width of the cursor */
height: 1em; /* Height of the cursor */
background-color: orange; /* Color of the cursor */
animation: blink-caret 1s step-end infinite;
}
  
/* Keyframes for blinking cursor effect */
@keyframes blink-caret {
from, to {
    opacity: 0;
}
50% {
    opacity: 1;
}
}

/* General styles for the h1 element with cursor-animation class */
.cursor-animation.name-h1 {
    font-size: 60px; /* Default font size */
    animation-delay: 3s; /* Animation delay */
  }
  
  /* Media query for screens with a max-width of 400px */
  @media (max-width: 400px) {
    .cursor-animation.name-h1 {
      font-size: 30px; /* Adjust font size for smaller screens */
    }
  .details-container{
    height: 36vh;
  }
  }

  @media (max-width: 440px) {
    .cursor-animation.name-h1 {
      font-size: 38px; /* Adjust font size for smaller screens */
    }
    .details-container{
      height: 36vh;
    }
  }

  @media (max-width: 400px) {
    .cursor-animation.name-h1 {
      font-size: 36px; /* Adjust font size for smaller screens */
    }
  .details-container{
    height: 56vh;
  }
  }

  @media (max-width: 391px) {
    .cursor-animation.name-h1 {
      font-size: 30px; /* Adjust font size for smaller screens */
    }
  .details-container{
    height: 56vh;
  }
  }

  button {
    width: 100px;
    padding: 10px;
    margin: 15px 0;
    border: 1px solid #ccc;
    border-radius: 20px;
    box-sizing: border-box;
    background-color: #ffffff;
    color: rgb(0, 0, 0);
    cursor: pointer;
  }
  
  button:hover {
    background-color: #2c7aee;
    border-color: #2c7aee;
    color: #ffffff;
  }
