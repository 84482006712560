.main-container{
    margin: 0px;
    height: auto; /* Height adjusts based on content */
    min-height: 40vh; /* Minimum height of the viewport height */
    /* background-color: #282C33; */
    background: linear-gradient(135deg, #010100 20%, #000000 50%, #000000 80%);
    box-sizing: border-box;
}

.box-container{
    margin: 20px 0px 0px 5px;
    background-color: white;
    height: 200px;
    width: 99%;
    border-radius: 10px;
    box-shadow: #000000;
}

html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    background-color: #282C33; /* Background color */
}
/* Container that holds all the box containers */
.container {
    display: flex; /* Enable flexbox layout */
    flex-wrap: wrap; /* Allow items to wrap onto the next line if necessary */
    gap: 16px; /* Optional: Add space between the items */
    padding: 16px; /* Optional: Add padding around the container */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

/* Individual box containers */
.box-container {
    padding-top: 0px;
    padding-left: 25px;
    background: linear-gradient(135deg, #010101 20%, #0d1f3f 50%, #000000 80%);
    /* background-color: #303033; */
    height: 200px; /* Fixed height for demonstration */
    width: calc(33% - 16px); /* Three items per row with spacing accounted for */
    /* Flexbox will automatically adjust the width for responsiveness */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
    border: 1px solid #000000;
}

/* Ensure responsiveness on smaller screens */
@media (max-width: 768px) {
    .box-container {
        height: fit-content;
        width: calc(50% - 16px); /* Two items per row */
    }
}

@media (max-width: 480px) {
    .box-container {
        height: fit-content;
        margin: 15px 0px 0px 4px;
        width: 100%; /* One item per row */
    }
}

/* Hide the <br> elements by default */
.conditional-br {
    display: none;
  }
  
  /* Show the <br> elements only on screens less than 600px wide */
  @media (max-width: 1024px) {
    .conditional-br {
      display: block; /* Show the <br> elements */
    }
  }